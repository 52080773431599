export default class CustodiesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeToken = "";
    this.treasuryToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.treasuryToken = data.treasuryToken;
    this.textSearch = data.textSearch;
  }
}
