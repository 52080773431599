import PaginationData from "@/models/general/PaginationData";
import Custody from "./Custody";
import CustodiesFilter from "./CustodiesFilter";

export default class Custodies {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.custodiesData = [];
    this.custody = new Custody();
    this.filterData = new CustodiesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.custodiesPagination);
    this.custodiesData = data.custodiesPagination.custodiesData;
  }
}
