import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import CustodiesFilter from "./CustodiesFilter";

export default class Custody {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyToken = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.treasuryToken = "";
    this.treasuryNameCurrent = "";
    this.custodyNameCurrent = "";
    this.custodyNameEn = "";
    this.custodyNameAr = "";
    this.custodyNameUnd = "";
    this.custodyValue = "";
    this.custodyRemainingValue = "";
    this.custodyDescriptionCurrent = "";
    this.custodyDescriptionEn = "";
    this.custodyDescriptionAr = "";
    this.custodyDescriptionUnd = "";
    this.custodyNotes = "";
    this.userMakeActionToken = "";
    this.userMakeActionNameCurrent = "";
    this.userMakeActionCode = "";
    this.userMakeActionImagePath = "";
    this.userMakeActionCountryCodeName = "";
    this.userMakeActionCountryCode = "";
    this.userMakeActionPhone = "";
    this.userMakeActionPhoneWithCC = "";
    this.userMakeActionEmail = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.cancellationStatus = false;
    this.userMakeCancellationToken = "";
    this.userMakeCancellationNameCurrent = "";
    this.userMakeCancellationCode = "";
    this.userMakeCancellationImagePath = "";
    this.userMakeCancellationCountryCodeName = "";
    this.userMakeCancellationCountryCode = "";
    this.userMakeCancellationPhone = "";
    this.userMakeCancellationPhoneWithCC = "";
    this.userMakeCancellationEmail = "";
    this.cancellationDateTime = "";
    this.cancellationDate = "";
    this.cancellationTime = "";
    this.settlementRemainingValue = "";
    this.settlementStatus = false;
    this.custodySettlementOperationDescriptionCurrent = "";
    this.custodySettlementOperationDescriptionEn = "";
    this.custodySettlementOperationDescriptionAr = "";
    this.custodySettlementOperationDescriptionUnd = "";
    this.custodySettlementOperationNotes = "";
    this.userMakeSettlementToken = "";
    this.userMakeSettlementNameCurrent = "";
    this.userMakeSettlementCode = "";
    this.userMakeSettlementImagePath = "";
    this.userMakeSettlementCountryCodeName = "";
    this.userMakeSettlementCountryCode = "";
    this.userMakeSettlementPhone = "";
    this.userMakeSettlementPhoneWithCC = "";
    this.userMakeSettlementEmail = "";
    this.settlementDateTime = "";
    this.settlementDate = "";
    this.settlementTime = "";
    this.custodyIsExpensed = false;
    this.custodyArchiveStatus = false;
  }
  fillData(data) {
    this.custodyToken = data.custodyToken;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.treasuryToken = data.treasuryToken;
    this.treasuryNameCurrent = data.treasuryNameCurrent;
    this.custodyNameCurrent = data.custodyNameCurrent;
    this.custodyNameEn = data.custodyNameEn;
    this.custodyNameAr = data.custodyNameAr;
    this.custodyNameUnd = data.custodyNameUnd;
    this.custodyValue = data.custodyValue;
    this.custodyRemainingValue = data.custodyRemainingValue;
    this.custodyDescriptionCurrent = data.custodyDescriptionCurrent;
    this.custodyDescriptionEn = data.custodyDescriptionEn;
    this.custodyDescriptionAr = data.custodyDescriptionAr;
    this.custodyDescriptionUnd = data.custodyDescriptionUnd;
    this.custodyNotes = data.custodyNotes;
    this.userMakeActionToken = data.userMakeActionToken;
    this.userMakeActionNameCurrent = data.userMakeActionNameCurrent;
    this.userMakeActionCode = data.userMakeActionCode;
    this.userMakeActionImagePath = data.userMakeActionImagePath;
    this.userMakeActionCountryCodeName = data.userMakeActionCountryCodeName;
    this.userMakeActionCountryCode = data.userMakeActionCountryCode;
    this.userMakeActionPhone = data.userMakeActionPhone;
    this.userMakeActionPhoneWithCC = data.userMakeActionPhoneWithCC;
    this.userMakeActionEmail = data.userMakeActionEmail;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.cancellationStatus = data.cancellationStatus;
    this.userMakeCancellationToken = data.userMakeCancellationToken;
    this.userMakeCancellationNameCurrent = data.userMakeCancellationNameCurrent;
    this.userMakeCancellationCode = data.userMakeCancellationCode;
    this.userMakeCancellationImagePath = data.userMakeCancellationImagePath;
    this.userMakeCancellationCountryCodeName =
      data.userMakeCancellationCountryCodeName;
    this.userMakeCancellationCountryCode = data.userMakeCancellationCountryCode;
    this.userMakeCancellationPhone = data.userMakeCancellationPhone;
    this.userMakeCancellationPhoneWithCC = data.userMakeCancellationPhoneWithCC;
    this.userMakeCancellationEmail = data.userMakeCancellationEmail;
    this.cancellationDateTime = data.cancellationDateTime;
    this.cancellationDate = data.cancellationDate;
    this.cancellationTime = data.cancellationTime;
    this.settlementRemainingValue = data.settlementRemainingValue;
    this.settlementStatus = data.settlementStatus;
    this.custodySettlementOperationDescriptionCurrent =
      data.custodySettlementOperationDescriptionCurrent;
    this.custodySettlementOperationDescriptionEn =
      data.custodySettlementOperationDescriptionEn;
    this.custodySettlementOperationDescriptionAr =
      data.custodySettlementOperationDescriptionAr;
    this.custodySettlementOperationDescriptionUnd =
      data.custodySettlementOperationDescriptionUnd;
    this.custodySettlementOperationNotes = data.custodySettlementOperationNotes;
    this.userMakeSettlementToken = data.userMakeSettlementToken;
    this.userMakeSettlementNameCurrent = data.userMakeSettlementNameCurrent;
    this.userMakeSettlementCode = data.userMakeSettlementCode;
    this.userMakeSettlementImagePath = data.userMakeSettlementImagePath;
    this.userMakeSettlementCountryCodeName =
      data.userMakeSettlementCountryCodeName;
    this.userMakeSettlementCountryCode = data.userMakeSettlementCountryCode;
    this.userMakeSettlementPhone = data.userMakeSettlementPhone;
    this.userMakeSettlementPhoneWithCC = data.userMakeSettlementPhoneWithCC;
    this.userMakeSettlementEmail = data.userMakeSettlementEmail;
    this.settlementDateTime = data.settlementDateTime;
    this.settlementDate = data.settlementDate;
    this.settlementTime = data.settlementTime;
    this.custodyIsExpensed = data.custodyIsExpensed;
    this.custodyArchiveStatus = data.custodyArchiveStatus;
  }

  async getAllCustodies(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new CustodiesFilter()
  ) {
    try {
      let response = await axios.get(
        `/Custodies/GetAllCustodies?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${filterData.employeeToken}&treasuryToken=${filterData.treasuryToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfCustodies(
    language,
    userAuthorizeToken,
    filterData = new CustodiesFilter()
  ) {
    return await axios.get(
      `/Custodies/GetDialogOfCustodies?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&employeeToken=${filterData.employeeToken}&treasuryToken=${filterData.treasuryToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getCustodyDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Custodies/GetCustodyDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&custodyToken=${this.custodyToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addCustody(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeToken: this.employeeToken,
      treasuryToken: this.treasuryToken,
      custodyValue: this.custodyValue,
      custodyDescriptionEn: this.custodyDescriptionEn,
      custodyDescriptionAr: this.custodyDescriptionAr,
      custodyDescriptionUnd: this.custodyDescriptionUnd,
      custodyNotes: this.custodyNotes,
    };

    try {
      return await axios.post(`/Custodies/AddCustody`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async settlementCustody(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyToken: this.custodyToken,
      treasuryToken: this.treasuryToken,
      custodySettlementOperationDescriptionEn:
        this.custodySettlementOperationDescriptionEn,
      custodySettlementOperationDescriptionAr:
        this.custodySettlementOperationDescriptionAr,
      custodySettlementOperationDescriptionUnd:
        this.custodySettlementOperationDescriptionUnd,
      custodySettlementOperationNotes: this.custodySettlementOperationNotes,
    };

    try {
      return await axios.post(`/Custodies/AddCustodySettlementOperation`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async cancelCustody(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyToken: this.custodyToken,
    };

    try {
      return await axios.post(`/Custodies/CancelCustody`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
