<template>
  <b-modal id="CustodySettlement" scrollable size="lg" centered hide-footer>
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/settlement.svg" class="icon-lg" />
        {{ $t("settlement") }}
      </h3>
    </template>

    <h4 class="text-center">{{ custody.custodyNameCurrent }}</h4>

    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="'treasuryToken'"
        :value="custody.treasuryToken"
        :options="treasuryTokenOptions"
        v-on:changeValue="custody.treasuryToken = $event"
        :title="$t('selectTreasury')"
        :imgName="'treasuries.svg'"
      />

      <TextArea
        :className="'col-md-6'"
        :id="'custodySettlementOperationDescriptionAr'"
        :value="custody.custodySettlementOperationDescriptionAr"
        v-on:changeValue="
          custody.custodySettlementOperationDescriptionAr = $event
        "
        :title="$t('Custodies.settlementDescriptionAr')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'custodySettlementOperationDescriptionEn'"
        :value="custody.custodySettlementOperationDescriptionEn"
        v-on:changeValue="
          custody.custodySettlementOperationDescriptionEn = $event
        "
        :title="$t('Custodies.settlementDescriptionEn')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'custodySettlementOperationDescriptionUnd'"
        :value="custody.custodySettlementOperationDescriptionUnd"
        v-on:changeValue="
          custody.custodySettlementOperationDescriptionUnd = $event
        "
        :title="$t('Custodies.settlementDescriptionUnd')"
        :imgName="'description.svg'"
      />
      <TextArea
        :className="'col-md-6'"
        :id="'custodySettlementOperationNotes'"
        :value="custody.custodySettlementOperationNotes"
        v-on:changeValue="custody.custodySettlementOperationNotes = $event"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>

    <div class="form-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="settlementCustody"
      >
        {{ $t("settlement") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('CustodySettlement')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import Treasury from "@/models/finance/treasuries/Treasury";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      treasuryData: new Treasury(),
      treasuryTokenOptions: [],
    };
  },
  methods: {
    async settlementCustody() {
      this.isLoading = true;
      try {
        let response = await this.custody.settlementCustody(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("CustodySettlement");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfTreasuries() {
      this.isLoading = true;
      try {
        this.treasuryTokenOptions =
          await this.treasuryData.getDialogOfTreasuries(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  props: ["custody"],
  computed: {},
  created() {
    this.getDialogOfTreasuries();
  },
};
</script>
