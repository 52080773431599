<template>
  <div v-if="custodies" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Custodies.employeeName") }}</th>
            <th>{{ $t("treasuryName") }}</th>
            <th>{{ $t("Custodies.value") }}</th>
            <th>{{ $t("Custodies.remainingValue") }}</th>
            <th>{{ $t("Custodies.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("info") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodyCancelAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodyCancelSelf
                )
              "
            >
              {{ $t("cancel") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodySettlementAddAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodySettlementAddSelf
                )
              "
            >
              {{ $t("settlement") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsViewAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsViewSelf
                )
              "
            >
              {{ $t("CustodyExpenseOperations.modelName") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(Custody, index) in custodies" :key="index">
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(Custody.employeeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Custody.treasuryNameCurrent) }}
            </td>
            <td>
              {{ moneyFormat(Custody.custodyValue) }}
            </td>
            <td>
              {{ moneyFormat(Custody.custodyRemainingValue) }}
            </td>
            <td>
              {{ isDataExist(Custody.custodyDescriptionCurrent) }}
            </td>
            <td>
              {{ isDataExist(Custody.custodyNotes) }}
            </td>
            <td>
              <button
                v-b-modal.CustodyInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setCustody(Custody)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodyCancelAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodyCancelSelf
                )
              "
            >
              <button
                v-if="!Custody.cancellationStatus"
                v-b-modal.CustodyCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="setCustody(Custody)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
              <template v-else>{{ $t("cancelDone") }}</template>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodySettlementAddAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                    .custodySettlementAddSelf
                )
              "
            >
              <button
                v-if="!Custody.settlementStatus"
                v-b-modal.CustodySettlement
                class="btn p-0"
                :title="$t('settlement')"
                @click="setCustody(Custody)"
              >
                <img src="@/assets/images/settlement.svg" class="icon-lg" />
              </button>
              <template v-else>{{ $t("Custodies.settlementDone") }}</template>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsViewAll
                ) ||
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegeCustodyExpenseOperations
                    .custodyExpenseOperationsViewSelf
                )
              "
            >
              <router-link
                :to="{
                  name: 'CustodyExpenseOperations',
                  params: {
                    custodyToken: Custody.custodyToken,
                  },
                }"
                :title="$t('CustodyExpenseOperations.modelName')"
              >
                <img
                  src="@/assets/images/CustodyExpenseOperations.svg"
                  class="icon-lg"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { isDataExist, moneyFormat } from "./../../../utils/functions";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["custodies", "pagination"],
  methods: {
    setCustody(data) {
      this.$emit("setCustody", data);
    },
    isDataExist,
    moneyFormat,
  },
  computed: {},
  async created() {},
};
</script>
