<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        custodies.custodiesData != undefined &&
        custodies.custodiesData.length != 0
      "
    >
      <CustodiesTable
        v-on:setCustody="custodies.custody.fillData($event)"
        :custodies="custodies.custodiesData"
        :pagination="custodies.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="custodies.pagination"
        v-on:changePage="changePage"
      />
      <CustodyInfo :custody="custodies.custody" />
      <CustodyCancel
        :custody="custodies.custody"
        v-on:refresh="getAllCustodies()"
      />
      <CustodySettlement
        :custody="custodies.custody"
        v-on:refresh="getAllCustodies()"
      />
    </div>

    <CustodyBtns
      :theFilterData="custodies.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustodiesTable from "@/components/finance/custodies/CustodiesTable.vue";
import CustodyInfo from "@/components/finance/custodies/CustodyInfo.vue";
import CustodyCancel from "@/components/finance/custodies/CustodyCancel.vue";
import CustodySettlement from "@/components/finance/custodies/CustodySettlement.vue";
import CustodyBtns from "@/components/finance/custodies/CustodyBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Custodies from "@/models/finance/custodies/Custodies";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustodiesTable,
    CustodyInfo,
    CustodyCancel,
    CustodySettlement,
    CustodyBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      custodies: new Custodies(),
    };
  },
  methods: {
    changePage(page) {
      this.custodies.pagination.selfPage = page;
      this.getAllCustodies();
    },
    search(data) {
      this.custodies.filterData.fillData(data);
      this.custodies.custodiesData = [];
      this.getAllCustodies();
    },
    async getAllCustodies() {
      this.isLoading = true;
      try {
        const response = await this.custodies.custody.getAllCustodies(
          this.language,
          this.userAuthorizeToken,
          this.custodies.pagination,
          this.custodies.filterData
        );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.custodies.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.custodies.custodiesData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.custodies.custodiesData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.custodies.custodiesData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.custodies.custodiesData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllCustodies();
  },
};
</script>
