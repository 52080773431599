<template>
  <b-modal id="CustodyInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/Custodies.svg" class="icon-lg" />
        {{ $t("Custodies.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.employeeNameCurrent"
        :title="$t('Custodies.employeeName')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.treasuryNameCurrent"
        :title="$t('treasuryName')"
        :imgName="'treasuries.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(custody.custodyValue)"
        :title="$t('Custodies.value')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(custody.custodyRemainingValue)"
        :title="$t('Custodies.remainingValue')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.custodyDescriptionAr"
        :title="$t('Custodies.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.custodyDescriptionEn"
        :title="$t('Custodies.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.custodyDescriptionUnd"
        :title="$t('Custodies.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.custodyNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="custody.userMakeActionNameCurrent"
        :title="$t('userMakeAction')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="formateDateTimeLang(custody.actionDate, custody.actionTime)"
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <div class="my-card col-12">
        <span class="my-card-title">{{
          `${$t("Custodies.cancellationStatus")}: (${
            custody.cancellationStatus ? $t("yes") : $t("no")
          })`
        }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.userMakeCancellationNameCurrent"
            :title="$t('userMakeCancellation')"
            :imgName="'employees.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              custody.cancellationDate
                ? formateDateTimeLang(
                    custody.cancellationDate,
                    custody.cancellationTime
                  )
                : $t('notFound')
            "
            :title="$t('cancellationDateTime')"
            :imgName="'dateAndTime.svg'"
          />
        </div>
      </div>

      <div class="my-card col-12">
        <span class="my-card-title">{{ $t("Custodies.settlement") }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.settlementStatus ? $t('yes') : $t('no')"
            :title="$t('Custodies.settlementStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="moneyFormat(custody.settlementRemainingValue)"
            :title="$t('Custodies.settlementRemainingValue')"
            :imgName="'money.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.custodySettlementOperationDescriptionAr"
            :title="$t('Custodies.settlementDescriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.custodySettlementOperationDescriptionEn"
            :title="$t('Custodies.settlementDescriptionEn')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.custodySettlementOperationDescriptionUnd"
            :title="$t('Custodies.settlementDescriptionUnd')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.custodySettlementOperationNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="custody.userMakeCancellationNameCurrent"
            :title="$t('userMakeCancellation')"
            :imgName="'employees.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              custody.cancellationDate
                ? formateDateTimeLang(
                    custody.cancellationDate,
                    custody.cancellationTime
                  )
                : $t('notFound')
            "
            :title="$t('cancellationDateTime')"
            :imgName="'dateAndTime.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { moneyFormat, formateDateTimeLang } from "@/utils/functions";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["custody"],
  methods: {
    moneyFormat,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>
